<mat-card [ngClass]="bgBlue ? 'bg-blue' : ''">
  <mat-card-title>
    <div class="template-title" *ngIf="title">
      <div class="back-button">
        <img class="arrow-icon" src="../../../../../assets/svg/arrow_back.svg" alt="">
      </div>

      <img src="../../../../../assets/svg/powered-by-monnet-payment-solutions.svg" class="logotipe"
        alt="powered by Monnet">
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="title-text" style="width: 183px;">
      <h2 style="font-size: 36px; margin-top: 0;">{{ title }}</h2>
    </div>
    <ng-content select="[slot=content]"></ng-content>
  </mat-card-content>
  <mat-card-actions *ngIf="showFooter">
    <img *ngIf="!bgBlue" src="../../../../../assets/svg/powered-by-monnet-payment-solutions.svg" class="logotipe"
      alt="powered by Monnet">
    <img *ngIf="bgBlue" src="../../../../../assets/logotipe/monnet-white.svg" class="logotipe" alt="powered by Monnet">
  </mat-card-actions>
</mat-card>