import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { KushkiComponent } from '../kushki/page/kushki/kushki.component';
import { PciProxyPageComponent } from '../pci-proxy/page/pci-proxy-page/pci-proxy-page.component';
import { SharedModule } from '../shared/shared.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UiModule } from '../ui/ui.module';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentRejectedComponent } from './components/payment-rejected/payment-rejected.component';
import { PaymentInProgressComponent } from './components/payment-in-progress/payment-in-progress.component';

const routes: Route[] = [
  {
    path: 'kushki',
    loadChildren: () => import('../kushki/kushki.module').then((m) => m.KushkiModule),
  },
  {
    path: 'pci-proxy',
    component: PciProxyPageComponent,
    loadChildren: () => import('../pci-proxy/pci-proxy.module').then((m) => m.PciProxyModule),
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: 'payment-rejected',
    component: PaymentRejectedComponent,
  },
  {
    path: 'payment-in-progress',
    component: PaymentInProgressComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
]

@NgModule({
  declarations: [NotFoundComponent, PaymentSuccessComponent, PaymentRejectedComponent, PaymentInProgressComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    UiModule,
  ]
})
export class RootModule { }
